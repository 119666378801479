import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom, faCopy } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SidebarHeader from "./SidebarHeader";
import styled from "@emotion/styled";
import Select from "react-select";

const GenerateSetWrapper = styled.div`
  form {
    width: 100%;
    margin: 0 auto;
  }

  input[type="number"] {
    height: 37px;
    width: 70px;
  }

  button {
    display: inline-block;
    margin-top: 0;
  }

  button.inline {
    margin-right: 10px;
    margin-left: 10px;
  }

  textarea {
    margin-bottom: 1rem;
    width: 100%;
  }
`;

const GenerateSet = ({
  generateSet,
  generatedSet,
  setGeneratedSet,
  categories,
  message,
}) => {
  const [setSize, setSetSize] = useState(10);
  const [selectedCategories, setSelectedCategories] = useState(null);

  const handleChangeSetSize = (e) => {
    setSetSize(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (setSize > 0) {
      generateSet(setSize, selectedCategories);
    }
  };

  const handleDropdownChange = (categories) => {
    const newCategories =
      categories && categories.map((category) => category.value);
    setSelectedCategories(newCategories);
  };

  const handleChangeTextArea = (e) => {
    setGeneratedSet(e.target.value);
  };

  const categoryList =
    categories &&
    categories.map((category) => ({
      label: category,
      value: category,
    }));
  return (
    <GenerateSetWrapper>
      <SidebarHeader
        label="Generate Set"
        icon={<FontAwesomeIcon color="#eee" icon={faRandom} />}
      />
      <p>{message.generate}</p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="numberOfHashtags">How many? (Does not include favorite hashtags)</label>
        <input
          type="number"
          id="numberOfHashtags"
          name="numberOfHashtags"
          onChange={handleChangeSetSize}
          value={setSize}
        />
        <button className="inline">
          <FontAwesomeIcon icon={faRandom} /> Generate
        </button>
        <label htmlFor="selectedCategories">From which categories?</label>
        <Select
          isMulti
          id="selectedCategories"
          name="selectedCategories"
          options={categoryList}
          className="selectCategory"
          onChange={handleDropdownChange}
        />
        <p className="small">
          Your favorite hashtags always appear at the beginning of your generated sets.
        </p>
        <label htmlFor="generatedSet">Generated hashtags:</label>
        <textarea
          name="generatedSet"
          id="generatedSet"
          rows="10"
          value={generatedSet}
          onChange={handleChangeTextArea}
          spellCheck="false"
        ></textarea>
      </form>
      <CopyToClipboard text={generatedSet}>
        <button>
          <FontAwesomeIcon icon={faCopy} /> Copy
        </button>
      </CopyToClipboard>
    </GenerateSetWrapper>
  );
};

export default GenerateSet;
