import React from "react";

const Dropdown = ({ data = [], setOpen, setSelectedValue, triggerAction }) => (
  <div className="dropdown">
    <ul>
      {data.map((item, i) => (
        <li
          key={i}
          onClick={() => {
            setOpen(false);
            setSelectedValue(item.label);
            if (triggerAction) {
              triggerAction(item.value);
            }
          }}
        >
          {item.label}
        </li>
      ))}
    </ul>
  </div>
);

export default Dropdown;
