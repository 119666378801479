import React from "react";
import { NavLink } from "react-router-dom";
import FixedWidth from "../layout/FixedWidth";
import styled from "@emotion/styled";
import Fade from "react-reveal/Fade";

const IntroWrapper = styled.div`
  text-align: center;
  padding-top: 7rem;

  h1 {
    max-width: 520px;
    margin: 2rem auto 1rem auto;
  }

  .logoIcon {
    width: 50px;
  }

  p {
    max-width: 520px;
    margin: 0 auto 2rem auto;
  }
`;

export default function Intro() {
  return (
    <FixedWidth>
      <Fade bottom>
        <IntroWrapper>
          <img
            className="logoIcon"
            src="logo-icon.png"
            alt="Hashzag Logo Icon"
          />
          <h1>
            Grow your following with a more organized approach to hashtags.
          </h1>
          <p>
            Instagram penalizes repetitive hashtags. Hashzag makes it easy to
            keep your posts fresh with unique sets of hashtags generated from
            your library of #favorites.
          </p>
          <div className="buttons">
            <NavLink to="/sign-up">
              <button className="primary">Sign Up</button>
            </NavLink>
            <NavLink to="/sign-in">
              <button className="primary contrast">Sign In</button>
            </NavLink>
          </div>
        </IntroWrapper>
      </Fade>
    </FixedWidth>
  );
}
