import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import styled from "@emotion/styled";
import Home from "./components/pages/Home";
import Dashboard from "./components/pages/Dashboard";
import SignIn from "./components/pages/SignIn";
import SignUp from "./components/pages/SignUp";
import SignOut from "./components/pages/SignOut";

const AppWrapper = styled.div``;

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <AppWrapper>
          <Route exact path="/" component={Home} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route path="/sign-in" component={SignIn} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/sign-out" component={SignOut} />
        </AppWrapper>
      </BrowserRouter>
    );
  }
}

export default App;
