import React from "react";
import styled from "@emotion/styled";

const CategoryWrapper = styled.div`
  margin: 3px 0 2px 0;
`;

const Category = ({ category }) => {
  return (
    <CategoryWrapper>
      <span className="category">{category}</span>
    </CategoryWrapper>
  );
};

export default Category;
