import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { signUpUser } from "../../store/actions/authActions";
import styled from "@emotion/styled";
import FixedWidth from "../layout/FixedWidth";
import Navigation from "../page-sections/Navigation";
import Footer from "../page-sections/Footer";
import Fade from "react-reveal/Fade";
import Helmet from "react-helmet";

const SignUpWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  margin-top: 100px;

  button {
    margin: 1.5rem 0 0.5rem 0;
    font-size: 16px;
    padding: 13px 20px;
    width: initial;
    height: initial;
  }

  p {
    line-height: 1.6;
  }
`;

const SignUp = ({ auth, signUpError, signUp }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    signUp({ email, password });
  };

  if (auth.uid) return <Redirect to="/dashboard" />;
  return (
    <FixedWidth>
      <Helmet>
        <title>Hashzag - Sign Up</title>
      </Helmet>
      <Navigation />
      <Fade bottom>
        <SignUpWrapper>
          <div className="authForm">
            <form onSubmit={handleSubmit}>
              <h1>Sign Up</h1>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="primary" type="submit">
                Sign Up
              </button>
            </form>
            {signUpError ? <p>{signUpError}</p> : null}
          </div>
        </SignUpWrapper>
      </Fade>
      <Footer />
    </FixedWidth>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUpUser(newUser)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseAuth.auth,
    signUpError: state.auth.signUpError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
