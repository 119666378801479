import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faCopy } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ControlWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 2;

  .message {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 900;
    font-size: 13px;
    color: var(--text-dark);
    display: inline-block;
    background: var(--highlight);
    margin: 0 5px;
    box-shadow: 0px 0px 1px 1px #00000054;

    span {
      margin: 0.5rem 1rem;
      display: inline-block;
    }
  }

  .selectControl {
    background: var(--highlight-contrast);
    cursor: pointer;
    color: var(--text-main);
    transition: 0.3s all ease;

    .icon {
      margin-right: 4px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  @media screen and (max-width: 830px) {
    right: 0;

    .message {
      display: block;
      border-radius: 4px;
      margin-bottom: 5px;
    }
  }
`;

const createHashtagMessage = (numHashtags) => {
  if (numHashtags > 1) {
    return `${numHashtags} Hashtags`;
  } else if (numHashtags === 1) {
    return "1 Hashtag";
  } else {
    return "No hashtags. Hmmm, why not add a couple?";
  }
};

const createSelectedMessage = (selectedHashtags) => {
  let numSelected = Object.keys(selectedHashtags).length;
  if (numSelected > 1) {
    return `${numSelected} Selected`;
  } else if (numSelected === 1) {
    return `1 Selected`;
  } else {
    return "";
  }
};

const SelectControls = ({
  hashtags,
  selectedHashtags,
  deselectAllHashtags,
}) => {
  const selectedList =
    selectedHashtags &&
    Object.keys(selectedHashtags).map((hashtag) => {
      return hashtag;
    });
  if (hashtags) {
    var hashtagMessage = createHashtagMessage(hashtags.length);
  }
  if (selectedHashtags) {
    var selectedMessage = createSelectedMessage(selectedHashtags);
  }
  return (
    <ControlWrapper>
      <div className="messages">
        <div className="message">
          {hashtagMessage ? <span>{hashtagMessage}</span> : null}
        </div>

        <div className="message">
          {selectedMessage ? <span>{selectedMessage}</span> : null}
        </div>
        <div className="message selectControl">
          {selectedMessage ? (
            <CopyToClipboard text={selectedList.join(" ")}>
              <span>
                <FontAwesomeIcon className="icon" icon={faCopy} />
                Copy Selected
              </span>
            </CopyToClipboard>
          ) : null}
        </div>
        <div className="message selectControl">
          {selectedMessage ? (
            <span onClick={() => deselectAllHashtags()}>
              <FontAwesomeIcon className="icon" icon={faMinusSquare} />
              Deselect All
            </span>
          ) : null}
        </div>
      </div>
    </ControlWrapper>
  );
};

export default SelectControls;
