import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faRandom,
  faGripHorizontal,
  faCopy,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import FixedWidth from "../layout/FixedWidth";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";

const FeatureWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 10rem 1rem 4rem 1rem;

  .columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }

  .keyFeatures {
    padding-top: 5rem;
    position: relative;
    z-index: 2;

    h2 {
      position: relative;
      z-index: 2;
    }
  }

  .feature {
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 10px;
    align-items: center;
    margin: 2.5rem 0;
    padding-right: 2rem;
    transition: 0.3s all ease;

    h3 {
      color: #eee;
      margin: 0;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .icon {
    background: var(--bg);
    color: #fff;
    border-radius: 50%;
    margin-right: 1em;
    width: 50px;
    height: 50px;
    padding: 13px;
  }

  .circle {
    background: var(--highlight-contrast);
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    position: absolute;
    top: 161px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }

  .hashtags {
    position: relative;
    z-index: 2;
    margin-bottom: -50px;
  }

  .hashtag {
    position: relative;
    padding: 1rem;
    border-radius: 10px;
    display: inline-block;
    background: var(--bg);
    color: #eee;
    border: 1px solid transparent;
    box-shadow: 0px 0px 5.36481px 2.0118px rgba(0, 0, 0, 0.2);
    transition: 0.3s all ease;

    &:hover {
      filter: brightness(1.3);
    }

    &.selected {
      background: var(--highlight-contrast);
    }

    h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 48px;
      line-height: 50px;
    }

    .category {
      margin-top: 10px;
      margin-bottom: 0;

      span {
        background: var(--text-subtle);
        font-size: 2rem;
        color: #000;
        padding: 5px 10px;
        border-radius: 10px;
      }
    }

    .close {
      background: #080715;
      border-radius: 50%;
      transition: 0.3s all ease;
      width: 30px;
      height: 30px;
      padding: 5px;
      display: flex;
      position: absolute;
      top: -9px;
      left: -7px;
      color: #67679d;

      &:hover {
        color: #bad0de;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .hashtags {
      zoom: 0.8;
      margin-top: 200px;
    }

    .feature {
      padding-right: 1rem;
    }
  }

  @media screen and (max-width: 812px) {
    .hashtags {
      zoom: 0.65;
      margin-top: 320px;
    }
  }

  @media screen and (max-width: 630px) {
    .columns {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    .hashtags {
      margin-top: -100px;
      margin-bottom: -100px;
    }

    .keyFeatures {
        margin-top: 0;
        margin-bottom: 50px;
        
        h2 {
            margin-top: 0;
        }
    }
  }
`;

const Feature = ({ text, icon }) => {
  return (
    <div className="feature">
      <FontAwesomeIcon icon={icon} className="icon" />
      <h3>{text}</h3>
    </div>
  );
};

const Hashtag = ({ name, category, style }) => {
  return (
    <Rotate top left>
      <div>
        <div className="hashtag" style={style}>
          <FontAwesomeIcon icon={faTimes} className="close" />
          <h3>{name}</h3>
          <p className="category">
            <span>{category}</span>
          </p>
        </div>
      </div>
    </Rotate>
  );
};

export default function Features() {
  return (
    <FeatureWrapper>
      <FixedWidth>
        <div className="columns">
          <div className="hashtags">
            <Hashtag
              name="#adventure"
              category="lifestyle"
              style={{
                left: "380px",
                top: "80px",
                transform: "rotate(8.5deg)",
                zoom: "0.7",
              }}
            />
            <Hashtag
              name="#freelancer"
              category="lifestyle"
              style={{
                left: "50px",
                top: "0px",
                transform: "rotate(-3.5deg)",
                zoom: "0.8",
              }}
            />
            <Hashtag
              name="#digitalnomad"
              category="lifestyle"
              style={{
                transform: "rotate(-9.3deg)",
                left: "140px",
                top: "-20px",
              }}
            />
            <Hashtag
              name="#cafelife"
              category="travel"
              style={{ left: "20px" }}
            />
            <Hashtag
              name="#javascript"
              category="coding"
              style={{
                left: "320px",
                top: "-150px",
                transform: "rotate(-5deg)",
                zoom: "0.8",
              }}
            />
            <Hashtag
              name="#womenintech"
              category="tech"
              style={{
                transform: "rotate(5deg)",
                left: "83px",
                top: "-111px",
                zoom: "0.9",
              }}
            />
            {/* <Hashtag
              name="#adventure"
              category="lifestyle"
              style={{
                left: "0px",
                top: "-101px",
                zoom: "0.8",
              }}
            />
            <Hashtag
              name="#100daysofcode"
              category="coding"
              style={{
                transform: "rotate(-15deg)",
                left: "402px",
                top: "-372px",
                zoom: "0.6",
              }}
            /> */}
          </div>
          <Fade right>
            <div className="keyFeatures">
              <h2>Key Features</h2>
              <Feature text="Add hashtags to your library" icon={faPlus} />
              <Feature
                text="Categorize your hashtags"
                icon={faGripHorizontal}
              />
              <Feature
                text="Generate random sets of hashtags from one or more categories"
                icon={faRandom}
              />
              <Feature
                text="Easily copy hashtag sets into your social media posts"
                icon={faCopy}
              />
            </div>
          </Fade>
        </div>
      </FixedWidth>
      <div className="circle"></div>
    </FeatureWrapper>
  );
}
