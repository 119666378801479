import React from "react";
import AddHashtag from "./AddHashtag";
import GenerateSet from "./GenerateSet";
import ManageCategories from "./ManageCategories";
import SidebarIcon from "./SidebarIcon";
import styled from "@emotion/styled";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const SidebarDrawerWrapper = styled.div`
  padding: 1rem;
  background: var(--sidebar-drawer-bg);
  background: linear-gradient(0deg, #171924, #303244);
  position: relative;

  .sidebarSection {
    border-bottom: 1px solid var(--bg-subtle);
    width: 100%;
    padding: 2rem 0;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.5;
    font-size: 1.05rem;
  }

  .toggle {
    position: absolute;
    right: -222px;
  }
`;

const SidebarDrawer = ({
  categories,
  message,
  hashtags,
  generateSet,
  generatedSet,
  setGeneratedSet,
  showSection,
  closeSidebar,
}) => {
  const sidebarMenuToDisplay = () => {
    if (showSection === "add") {
      return (
        <AddHashtag
          className="sidebarSection"
          hashtags={hashtags}
          categories={categories}
          message={message}
        />
      );
    }
    if (showSection === "generate") {
      return (
        <GenerateSet
          generateSet={generateSet}
          generatedSet={generatedSet}
          setGeneratedSet={setGeneratedSet}
          hashtags={hashtags}
          categories={categories}
          message={message}
        />
      );
    }
    if (showSection === "manage") {
      return <ManageCategories categories={categories} message={message} />;
    }
  };

  return (
    <SidebarDrawerWrapper className="sidebarDrawer">
      <SidebarIcon
        icon={faChevronLeft}
        cls="toggle"
        onClick={() => {
          closeSidebar();
        }}
      />
      <div className="sidebarSection">{sidebarMenuToDisplay()}</div>
    </SidebarDrawerWrapper>
  );
};

export default SidebarDrawer;
