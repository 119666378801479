export const deleteHashtag = (name) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const authorUid = getState().firebaseAuth.auth.uid;
    firestore
      .collection("users")
      .doc(authorUid)
      .get()
      .then((snapshot) => {
        let currentHashtags = snapshot.data().hashtags
          ? snapshot.data().hashtags
          : [];

        let newHashtags = currentHashtags.filter((hashtag) => {
          return hashtag.name !== name;
        });

        firestore
          .collection("users")
          .doc(authorUid)
          .update({
            hashtags: newHashtags,
          })
          .then(() => {
            dispatch({ type: "DELETE_HASHTAG", name: name });
          })
          .catch((err) => {
            dispatch({ type: "DELETE_HASHTAG_ERROR", err });
          });
      })
      .catch((err) => {
        console.log("Error fetching user", err);
      });
  };
};

export const addHashtag = (name, category, favorite) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const authorUid = getState().firebaseAuth.auth.uid;
    firestore
      .collection("users")
      .doc(authorUid)
      .get()
      .then((snapshot) => {
        let currentHashtags = snapshot.data().hashtags
          ? snapshot.data().hashtags
          : [];

        let currentCategories = snapshot.data().categories
          ? snapshot.data().categories
          : [];

        let newCategories = currentCategories.includes(category)
          ? currentCategories
          : [...currentCategories, category];

        firestore
          .collection("users")
          .doc(authorUid)
          .update({
            hashtags: [
              ...currentHashtags,
              { name, category, favorite: favorite, dateAdded: new Date() },
            ],
            categories: newCategories,
          })
          .then(() => {
            dispatch({ type: "ADD_HASHTAG", name, category, favorite });
          })
          .catch((err) => {
            dispatch({ type: "ADD_HASHTAG_ERROR", err });
          });
      })
      .catch((err) => {
        console.log("Error fetching user", err);
      });
  };
};

export const updateCategoryCount = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("categories")
      .doc(id)
      .get()
      .then((snapshot) => {
        firestore
          .collection("categories")
          .doc(id)
          .update({ count: snapshot.data().count + 1 })
          .then(() => {
            dispatch({ type: "UPDATE_CATEGORY", id });
          })
          .catch((err) => {
            dispatch({ type: "UPDATE_CATEGORY_ERROR", err });
          });
      })
      .catch((err) => {
        console.log("Error fetching category", err);
      });
  };
};

export const addCategory = (category) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const authorUid = getState().firebaseAuth.auth.uid;
    firestore
      .collection("users")
      .doc(authorUid)
      .get()
      .then((snapshot) => {
        let currentCategories = snapshot.data().categories
          ? snapshot.data().categories
          : [];

        let newCategories = [...currentCategories, category];

        firestore
          .collection("users")
          .doc(authorUid)
          .update({
            categories: newCategories,
          })
          .then(() => {
            dispatch({ type: "ADD_CATEGORY", category });
          })
          .catch((err) => {
            dispatch({ type: "ADD_CATEGORY_ERROR", err });
          });
      })
      .catch((err) => {
        console.log("Error fetching user", err);
      });
  };
};

export const deleteCategory = (category) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const authorUid = getState().firebaseAuth.auth.uid;
    firestore
      .collection("users")
      .doc(authorUid)
      .get()
      .then((snapshot) => {
        let currentCategories = snapshot.data().categories
          ? snapshot.data().categories
          : [];

        let newCategories = currentCategories.filter((c) => {
          return c !== category;
        });

        firestore
          .collection("users")
          .doc(authorUid)
          .update({
            categories: newCategories,
          })
          .then(() => {
            dispatch({ type: "DELETE_CATEGORY_SUCCESS", category });
          })
          .catch((err) => {
            dispatch({ type: "DELETE_CATEGORY_ERROR", err });
          });
      })
      .catch((err) => {
        console.log("Error fetching user", err);
      });
  };
};

export const deleteCategoryAndHashtags = (category) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const authorUid = getState().firebaseAuth.auth.uid;
    firestore
      .collection("users")
      .doc(authorUid)
      .get()
      .then((snapshot) => {
        let currentHashtags = snapshot.data().hashtags
          ? snapshot.data().hashtags
          : [];

        let newHashtags = currentHashtags.filter((hashtag) => {
          return hashtag.category !== category;
        });

        let currentCategories = snapshot.data().categories
          ? snapshot.data().categories
          : [];

        let newCategories = currentCategories.filter((c) => {
          return c !== category;
        });

        firestore
          .collection("users")
          .doc(authorUid)
          .update({
            hashtags: newHashtags,
            categories: newCategories,
          })
          .then(() => {
            dispatch({
              type: "DELETE_CATEGORY_AND_HASHTAGS_SUCCESS",
              category,
            });
          })
          .catch((err) => {
            dispatch({ type: "DELETE_CATEGORY_AND_HASHTAGS_ERROR", err });
          });
      })
      .catch((err) => {
        console.log("Error fetching user", err);
      });
  };
};

export const selectHashtag = (name) => {
  return { type: "SELECT_HASHTAG", name: name };
};

export const deselectAllHashtags = () => {
  return { type: "DESELECT_ALL_HASHTAGS" };
};

export const sortHashtags = (property, ascending) => {
  return {
    type: "SORT_HASHTAGS",
    property: property,
    ascending: ascending,
  };
};

export const updateAddMessage = (message) => {
  return {
    type: "UPDATE_ADD_MESSAGE",
    message: message,
  };
};

export const updateMessage = (message) => {
  return {
    type: "UPDATE_MESSAGE",
    message: message,
  };
};
