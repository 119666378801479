import React from "react";
import styled from "@emotion/styled";

const FixedWidthWrapper = styled.div`
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
`;

export default function FixedWidth(props) {
  return <FixedWidthWrapper>{props.children}</FixedWidthWrapper>;
}
