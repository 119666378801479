import React, { useState } from "react";
import SidebarHeader from "./SidebarHeader";
import styled from "@emotion/styled";
import {
  faGripHorizontal,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  addCategory,
  deleteCategory,
  deleteCategoryAndHashtags,
} from "../../../store/actions/hashtagActions";

const ManageCategoriesWrapper = styled.div`
  input {
    width: 63%;
    margin-right: 10px;
  }

  button {
    margin-top: 0;
    width: 32%;
  }
`;

const CategoryCapsuleWrapper = styled.div`
  margin-top: 1rem;
`;


const CategoryCapsule = styled.div`
  background: var(--bg);
  color: var(--text-main);
  border: 1px solid var(--bg-dark);
  padding: 0.5rem 0.75rem;
  margin: 0.5rem auto;
  border-radius: 4px;
  transition: 0.3s all ease;
  font-size: 14px;

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .delete {
    font-size: 10px;
  }

  span {
    display: inline-block;
    background: var(--bg);
    font-size: 11px;
    color: var(--text-subtle);
    border: 1px solid var(--bg-dark);
    padding: 4px 6px;
    border-radius: 2px;
    cursor: pointer;

    &:first-of-type {
      margin-right: 10px;
    }

    &:hover {
      filter: brightness(1.2);
    }
  }
`;

const ManageCategories = ({
  categories,
  deleteCategory,
  addCategory,
  deleteCategoryAndHashtags,
  message
}) => {
  const [newCategory, setNewCategory] = useState("");
  const categoriesList =
    categories &&
    categories.map((category) => {
      return (
        <CategoryCapsule key={category}>
          <div className="category">{category}</div>
          <span
            className="delete"
            onClick={() => {
              deleteCategory(category);
            }}
          >
            <FontAwesomeIcon className="icon" icon={faTimes} />{" "}
            Category
          </span>
          <span
            className="delete"
            onClick={() => {
              deleteCategoryAndHashtags(category);
            }}
          >
            <FontAwesomeIcon className="icon" icon={faTrash} />{" "}
            Category + Hashtags
          </span>
        </CategoryCapsule>
      );
    });
  const isDuplicateCategory = () => {
    const found = !categories
      ? undefined
      : categories.find((category) => {
          return category.category === newCategory;
        });
    return found !== undefined;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newCategory) {
      if (!isDuplicateCategory()) {
        addCategory(newCategory);
        setNewCategory("");
      }
    }
  };
  return (
    <ManageCategoriesWrapper>
      <SidebarHeader
        label="Manage Categories"
        icon={<FontAwesomeIcon color="#eee" icon={faGripHorizontal} />}
      />
      <p>{message.categories}</p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="new-category">Add New:</label>
        <input
          type="text"
          onChange={(e) => setNewCategory(e.target.value)}
          value={newCategory}
          name="new-category"
          placeholder="Enter a category"
          id="new-category"
          autoComplete="off"
          spellCheck="false"
        />
        <button className="icon-button">
          <FontAwesomeIcon icon={faPlus} /> Add
        </button>
      </form>
      <CategoryCapsuleWrapper>
        <label>Existing:</label>
        {categoriesList}
      </CategoryCapsuleWrapper>
    </ManageCategoriesWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCategory: (category) => {
      dispatch(addCategory(category));
    },
    deleteCategory: (category) => {
      dispatch(deleteCategory(category));
    },
    deleteCategoryAndHashtags: (category) => {
      dispatch(deleteCategoryAndHashtags(category));
    },
  };
};

export default connect(null, mapDispatchToProps)(ManageCategories);
