import React, { useState } from "react";
import { connect } from "react-redux";
import { signInUser } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";
import styled from "@emotion/styled";
import FixedWidth from "../layout/FixedWidth";
import Navigation from "../page-sections/Navigation";
import Footer from "../page-sections/Footer";
import Fade from "react-reveal/Fade";
import Helmet from "react-helmet";

const SignInWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  padding-bottom: 4rem;
  margin-top: 100px;

  button {
    margin: 1.5rem 0 0.5rem 0;
    font-size: 16px;
    padding: 13px 20px;
    width: initial;
    height: initial;
  }

  p {
    line-height: 1.6;
  }
`;

const SignIn = ({ auth, signIn, signInError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn({ email, password });
    setPassword("");
  };

  if (auth.uid) return <Redirect to="/dashboard" />;

  return (
    <FixedWidth>
      <Helmet>
        <title>Hashzag - Sign In</title>
      </Helmet>
      <Navigation />
      <Fade bottom>
        <SignInWrapper>
          <div className="authForm">
            <form onSubmit={handleSubmit}>
              <h1>Sign In</h1>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="primary" type="submit">
                Sign In
              </button>
              {signInError ? <p>{signInError}</p> : null}
            </form>
          </div>
        </SignInWrapper>
      </Fade>
      <Footer />
    </FixedWidth>
  );
};

const mapStateToProps = (state) => {
  return {
    signInError: state.auth.signInError,
    auth: state.firebaseAuth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => dispatch(signInUser(credentials)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
