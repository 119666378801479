import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOutUser } from "../../store/actions/authActions";

function SignedInLinks({ signOut }) {
  return (
    <>
      <li>
        <NavLink to="/dashboard">Dashboard</NavLink>
      </li>
      <li>
        <a onClick={signOut} href="/">
          Log Out
        </a>
      </li>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOutUser()),
  };
};

export default connect(null, mapDispatchToProps)(SignedInLinks);
