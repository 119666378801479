import React from "react";
import styled from "@emotion/styled";

const SidebarHeaderWrapper = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-subtle);
  // cursor: pointer;
  // transition: background-color 0.15s ease;
  // transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1),
  //   padding 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  justify-content: center;

  .sidebarHeaderLabel {
    margin-left: 0.5rem;
  }
`;

const SidebarHeader = ({ icon, label, cls, onClick }) => {
  return (
    <SidebarHeaderWrapper
      className={`sidebarHeaderWrapper ${cls}`}
      onClick={onClick}
    >
      {icon}
      <span className="sidebarHeaderLabel">{label}</span>
    </SidebarHeaderWrapper>
  );
};

export default SidebarHeader;
