import React from "react";
import styled from "@emotion/styled";
import ButtonWithDropdown from "./ButtonWithDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
} from "@fortawesome/free-solid-svg-icons";

const ControlWrapper = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--bg-subtle);
  margin-bottom: 1rem;
`;

const optionsList = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "category",
    label: "Category",
  },
  {
    value: "dateAdded",
    label: "Date Added",
  },
];

const OrganizeControls = ({
  sortBy
}) => {
  return (
    <ControlWrapper>
      <ButtonWithDropdown
        triggerAction={sortBy}
        buttonText="Sort By"
        data={optionsList}
        icon={<FontAwesomeIcon className="icon" icon={faSort} />}
      />
    </ControlWrapper>
  );
};

export default OrganizeControls;
