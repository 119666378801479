import React from "react";
import styled from "@emotion/styled";
import FixedWidth from "../layout/FixedWidth";
import Fade from "react-reveal/Fade";

const FAQsWrapper = styled.div`
  background: #f7f7f7;
  padding: 5rem 2rem;

  h2,
  h3 {
    color: #1c1e2b;
    margin-top: 0;
  }

  .columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }

  p {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 630px) {
    .columns {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
  }
`;

const LeftCol = () => {
  return (
    <div>
      <h3>What is Hashzag?</h3>
      <p>A web-based hashtag organizer.</p>

      <h3>Who is Hashzag for?</h3>
      <p>
        Instagram power users who create and schedule posts using a computer
        (i.e. typically via services such as Later or Buffer).
      </p>

      <h3>Why did you build Hashzag?</h3>
      <p>
        I have been using a Google Spreadsheet to organize and categorize my
        Instagram hashtags. Depending on the type of post, I used formulas to
        generate randomized sets of hashtags from different categories, for
        example “web development” and “startups”. The whole thing became
        unbearably messy, so I decided to build something to solve my problem. I
        hope it solves yours too.
      </p>
    </div>
  );
};

const RightCol = () => {
  return (
    <div>
      <h3>What devices are supported?</h3>
      <p>
        Hashzag is a web app, which means it runs in all major web browsers, on desktop and on mobile.
      </p>

      <h3>Any plans to release a native mobile app?</h3>
      <p>
        Please send me a message in the feedback box (lower left) so I know that
        there’s interest in a mobile version. Thank you.
      </p>

      <h3>Where do I submit a bug or feedback?</h3>
      <p>
        Please drop me a comment in the feedback box (lower left). Thank you.
      </p>
    </div>
  );
};

export default function FAQs() {
  return (
    <FAQsWrapper>
      <Fade bottom>
        <FixedWidth>
          <h2>FAQs</h2>
          <div className="columns">
            <LeftCol />
            <RightCol />
          </div>
        </FixedWidth>
      </Fade>
    </FAQsWrapper>
  );
}
