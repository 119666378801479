import React from "react";
import styled from "@emotion/styled";
import {
  faPlus,
  faRandom,
  faGripHorizontal,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import SidebarIcon from "./SidebarIcon";

const SidebarWrapper = styled.div`
  background: var(--sidebar-bg);
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: inset -3px 3px 7px #00000040;
  z-index: 2;

  .logo-holder {
    text-align: center;
  }

  .logo {
    width: 55%;
    margin: 1rem auto;
    max-width: 80px;
  }

  @media screen and (max-width: 600px) {
    min-height: auto;
    padding-bottom: 1rem;
  }
`;

const Sidebar = ({ signOut, toggleSidebar }) => {
  return (
    <SidebarWrapper>
      <NavLink to="/dashboard" className="logo-holder">
        <img src="logo-icon.png" alt="Hashzag logo" className="logo" />
      </NavLink>
      <SidebarIcon
        icon={faPlus}
        label="Add Hashtag"
        onClick={() => {
          toggleSidebar("add");
        }}
      />
      <SidebarIcon
        icon={faGripHorizontal}
        label="Manage Categories"
        onClick={() => {
          toggleSidebar("manage");
        }}
      />
      <SidebarIcon
        icon={faRandom}
        label="Generate Set"
        onClick={() => {
          toggleSidebar("generate");
        }}
      />
      <SidebarIcon icon={faSignOutAlt} label="Sign Out" onClick={signOut} />
    </SidebarWrapper>
  );
};

export default Sidebar;
