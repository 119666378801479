import React from "react";
import styled from "@emotion/styled";
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
} from "react-share";

const ShareWrapper = styled.div`
  text-align: center;
  padding: 4rem 0;

  .buttons {
    margin-top: 2rem;
  }

  button {
    margin: 0rem 0.5rem 4rem 0.5rem;
    transition: 0.3s all ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  hr {
    width: 250px;
    margin: 0 auto;
    border: none;
    border-bottom: 2px solid #40424e;
  }
`;

export default function Share() {
  const shareURL = "https://hashzag.com";
  const tagLine =
    "Save and organize groups of hashtags. Easily copy and paste hashtags into your Instagram posts.";

  return (
    <ShareWrapper>
      <h2>Share Hashzag with a friend?</h2>
      <div className="buttons">
        <TwitterShareButton url={shareURL} title={tagLine} via={"hashzag"}>
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>
        <FacebookShareButton url={shareURL} quote={tagLine}>
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>
      </div>

      <hr />
    </ShareWrapper>
  );
}
