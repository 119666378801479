export const signInUser = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "SIGN_IN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGN_IN_ERROR", err });
      });
  };
};

export const signOutUser = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGN_OUT_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGN_OUT_ERROR", err });
      });
  };
};

export const signUpUser = (newUser) => {
  return (dispatch, action, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            hashtags: [],
            categories: [],
          })
          .then(() => {
            dispatch({ type: "SIGN_UP_SUCCESS" });
          })
          .catch((err) => {
            console.log("Unable to create user");
          });
      })
      .catch((err) => {
        dispatch({ type: "SIGN_UP_ERROR", err });
      });
  };
};
