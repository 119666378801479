import React from "react";
import { NavLink } from "react-router-dom";

function SignedOutLinks() {
  return (
    <>
      <li>
        <NavLink to="/sign-up">Sign Up</NavLink>
      </li>
      <li>
        <NavLink to="/sign-in">Sign In</NavLink>
      </li>
    </>
  );
}

export default SignedOutLinks;
