import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import SignedInLinks from "../layout/SignedInLinks";
import SignedOutLinks from "../layout/SignedOutLinks";
import FixedWidth from "../layout/FixedWidth";
import { connect } from "react-redux";

const Navigation = ({ auth }) => {
  const NavLinkWrapper = styled.div`
    display: grid;
    grid-template-columns: 150px 3fr;
    grid-gap: 30px;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    padding: 0 2rem;

    .logo {
      width: 150px;
    }

    ul {
      text-align: right;

      li {
        display: inline;
      }

      a {
        color: var(--text-subtle);
        font-size: 16px;
        font-weight: 500;
        margin: 0 2rem;
        padding-bottom: 0.25rem;

        &:hover {
          color: #cd6fff;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      a.active {
        color: #cd6fff;
      }
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: 1fr;
      text-align: center;

      ul {
        text-align: center;

        a {
          margin: 0 1rem;

          &:last-of-type {
            margin-right: 1rem;
          }
        }
      }
    }
  `;

  return (
    <FixedWidth>
      <NavLinkWrapper>
        <NavLink to="/" exact>
          <img className="logo" src="logo.png" alt="Hashzag Logo" />
        </NavLink>
        <ul>
          <li>
            <NavLink to="/" exact>
              Home
            </NavLink>
          </li>
          {auth.uid ? <SignedInLinks /> : <SignedOutLinks />}
        </ul>
      </NavLinkWrapper>
    </FixedWidth>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseAuth.auth,
  };
};

export default connect(mapStateToProps)(Navigation);
