import React from "react";
import styled from "@emotion/styled";
import Navigation from "../page-sections/Navigation";
import Footer from "../page-sections/Footer";
import Intro from "../page-sections/Intro";
import Features from "../page-sections/Features";
import FAQs from "../page-sections/FAQs";
import Share from "../page-sections/Share";
import Projects from "../page-sections/Projects";

const HomeWrapper = styled.div``;

const Home = (props) => {
  return (
    <>
      <Navigation />
      <HomeWrapper>
        <Intro />
        <Features />
        <FAQs />
        <Share />
        <Projects />
      </HomeWrapper>
      <Footer />
    </>
  );
};

export default Home;
