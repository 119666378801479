import React from "react";
import FixedWidth from "../layout/FixedWidth";
import styled from "@emotion/styled";
import Emoji from "../layout/Emoji";
import Fade from "react-reveal/Fade";

const ProjectsWrapper = styled.div`
  padding: 2rem 0;

  h3,
  p {
    text-align: center;
  }

  h3 {
    margin-top: 0px;
  }

  .author {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    justify-content: space-between;
    margin: 9rem 4rem 4rem 4rem;

    & > div {
      border: 2px solid #40424e;
      border-radius: 10px;
      position: relative;
      transition: 0.1s all ease;
      background: var(--bg-dark);

      &:hover {
        background: #272938;
      }

      h3 {
        margin-top: 3.5rem;
      }

      a {
        padding: 1rem;
        width: 100%;
        height: 100%;
        display: block;
      }

      img {
        background: #eee;
        width: 80px;
        border-radius: 50%;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 140px;
      }
    }
  }

  @media screen and (max-width: 812px) {
    .projects {
      margin: 4rem;
      grid-template-columns: 1fr;
      justify-content: center;

      & > div {
        h3 {
          margin-top: 2rem;
        }

        img {
          position: unset;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
`;

const Project = ({ link, name, description, image, alt }) => {
  return (
    <Fade left>
      <div>
        {/*eslint-disable-next-line*/}
        <a href={link} target="_blank" rel="noopener">
          <div>
            <img src={image} alt={alt} />
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
        </a>
      </div>
    </Fade>
  );
};

export default function Projects() {
  return (
    <ProjectsWrapper>
      <FixedWidth>
        <h3>Check out my other projects:</h3>
        <div className="projects">
          <Project
            name="PeerWyse"
            description="Salary estimates for people you know"
            image="logo-peerwyse.png"
            link="https://peerwyse.com"
            alt="PeerWyse Logo"
          />
          <Project
            name="Grattr"
            description="Your globally recognized attributes"
            image="logo-grattr.png"
            link="https://grattr.org"
            alt="Grattr Logo"
          />
          <Project
            name="Dotnotes"
            description="Printable 1-page technical cheatsheets"
            image="logo-dotnotes.png"
            link="https://amypeniston.com/dotnotes"
            alt="Dotnotes logo"
          />
        </div>
        <p className="author">
          Made by <a href="https://amypeniston.com">Amy</a>
          <Emoji symbol="🌵" label="cactus" />
        </p>
      </FixedWidth>
    </ProjectsWrapper>
  );
}
