import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import {
  addHashtag,
  addCategory,
  updateCategoryCount,
  updateAddMessage,
} from "../../../store/actions/hashtagActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SidebarHeader from "./SidebarHeader";
import CreateableSelect from "react-select/creatable";

const AddHashtagWrapper = styled.div`
  form {
    width: 100%;
    margin: 0 auto;
  }

  .radio-group {
    color: var(--text-subtle);
    font-size: 0.9rem;
  }

  input[type="radio"] {
    width: auto;
  }
`;

class AddHashtag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      category: null,
      favorite: false,
    };
  }

  isDuplicateHashtag = () => {
    const found = this.props.hashtags.find((hashtag) => {
      return hashtag.name === this.state.name;
    });
    return found !== undefined;
  };

  isDuplicateCategory = () => {
    const found = !this.props.categories
      ? undefined
      : this.props.categories.find((category) => {
          return category.category === this.state.category;
        });
    return found;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.name && this.state.category) {
      if (this.isDuplicateHashtag()) {
        this.props.updateAddMessage(
          `Hashtag already exists: ${this.state.name}`
        );
      } else {
        this.props.addHashtag(
          this.state.name,
          this.state.category,
          this.state.favorite
        );
        this.setState({
          name: "",
        });
      }
    } else if (!this.state.name) {
      this.props.updateAddMessage(`Hashtag cannot be blank.`);
    } else if (!this.state.category) {
      this.props.updateAddMessage(`Category cannot be blank.`);
    } else {
      this.props.updateAddMessage(
        `Enter a hashtag and a category and click Add.`
      );
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDropdownChange = (category) => {
    this.setState({
      ...this.state,
      category: category ? category.value : "",
    });
  };

  handleRadioChange = (e) => {
    this.setState({
      ...this.state,
      favorite: e.target.value === "true" ? true : false,
    });
  };

  render() {
    const { categories, message } = this.props;
    const categoryList =
      categories &&
      categories.map((category) => ({
        label: category,
        value: category,
      }));
    return (
      <AddHashtagWrapper>
        <SidebarHeader
          label="Add Hashtag"
          icon={<FontAwesomeIcon color="#eee" icon={faPlus} />}
        />
        <p>{message.add}</p>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="name">Hashtag</label>
          <input
            type="text"
            onChange={this.handleChange}
            value={this.state.name}
            name="name"
            placeholder="Enter a #hashtag"
            id="name"
            autoComplete="off"
            spellCheck="false"
          />
          <label htmlFor="category">Category</label>
          <CreateableSelect
            isClearable
            className="selectCategory"
            options={categoryList}
            onChange={this.handleDropdownChange}
            id="category"
            placeholder="Select or type to add"
          />
          <label htmlFor="favorite">Favorite?</label>
          <div className="radio-group">
            <input
              type="radio"
              value="false"
              name="favorite"
              checked={this.state.favorite === false}
              onChange={this.handleRadioChange}
            />{" "}
            No
            <input
              type="radio"
              value="true"
              name="favorite"
              checked={this.state.favorite === true}
              onChange={this.handleRadioChange}
            />{" "}
            Yes
          </div>
          <p className="small">
            Favorite hashtags appear in every generated set.
          </p>
          <button className="icon-button">
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </form>
      </AddHashtagWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addHashtag: (name, category, favorite) => {
      dispatch(addHashtag(name, category, favorite));
    },
    addCategory: (category) => {
      dispatch(addCategory(category));
    },
    updateCategory: (id) => {
      dispatch(updateCategoryCount(id));
    },
    updateAddMessage: (message) => {
      dispatch(updateAddMessage(message));
    },
  };
};

export default connect(null, mapDispatchToProps)(AddHashtag);
