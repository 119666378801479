import React from "react";

const Date = ({ date_added }) => {
  return (
    <>
      <span className="date">Added: {date_added}</span>
    </>
  );
};

export default Date;
