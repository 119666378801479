import React, { useState } from "react";
import styled from "@emotion/styled";

const FeedbackContainer = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 25px;
  position: fixed;
  left: 0;
  right: 0;
  top: 3rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  padding: 25px;
  box-shadow: 0px 0px 12px #0000003d;

  button {
    color: #fff;

    & + button {
      margin-left: 10px;
    }

    &.close {
      background-color: var(--bg-subtle);
    }
  }

  .logo {
    width: 20px;
    margin: 0 auto 30px auto;
    display: block;
  }

  h3 {
    color: var(--bg-dark);
    margin-top: 0;
    font-size: 1.4rem;
  }

  p {
    margin: 10px 0;
  }

  label {
    color: var(--bg-subtle);
  }

  textarea {
    display: block;
    width: 100%;
    height: 100px;
  }
`;

export const Feedback = ({ show, setShow, message }) => {
  const [status, setStatus] = useState(null);

  const handleClose = () => {
    setStatus(null);
    setShow(false);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  };
  return (
    <div>
      {show ? (
        <FeedbackContainer>
          <img src="logo-icon.png" alt="Hashzag feedback" className="logo" />

          <h3>Send Feedback</h3>
          <p>{message}</p>

          <form
            onSubmit={handleSubmit}
            action="https://formspree.io/f/xqkgkkde"
            method="post"
          >
            <label htmlFor="name">Your Name</label>
            <input name="name" id="name" type="text" />
            <label htmlFor="email">Your Email</label>
            <input name="email" id="email" type="email" />
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" type="text" />
            {status === "SUCCESS" ? (
              <>
                <p>Thank you for your message!</p>
                <button onClick={handleClose} className="close">
                  Close
                </button>
              </>
            ) : (
              <>
                <button type="submit">Submit</button>
                <button onClick={handleClose} className="close">
                  Close
                </button>
              </>
            )}
            {status === "ERROR" ? (
              <p>
                Oops! Something went wrong. Please email me at
                amy@amypeniston.com
              </p>
            ) : null}
          </form>
        </FeedbackContainer>
      ) : null}
    </div>
  );
};
