import React from "react";
import Helmet from "react-helmet";

const SEO = (props) => {
  const title = "Hashzag - Organize Your Hashtags";
  const description =
    "Save and organize groups of hashtags. Easily copy and paste hashtags into your Instagram posts.";
  const url = "https://hashzag.com";

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* OpenGraph tags */}
      <meta name="og:url" content={url} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta
        name="og:image"
        content="https://hashzag.com/facebook-social-image.jpg"
      />
      <meta name="og:type" content="website" />
      <meta name="fb:app_id" content="354647279000904" />
      {/* Twitter Card tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content="https://hashzag.com/twitter-social-image.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@hashzag" />
      <meta name="twitter:creator" content="@amypeniston" />
    </Helmet>
  );
};

export default SEO;
