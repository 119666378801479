const initState = {
  message: {
    add: "Enter a hashtag and a category and click Add.",
    categories: "Enter a new category or delete existing categories.",
    generate: "Choose how many hashtags to randomly select and from which categories, then click Generate."
  },
  selectedHashtags: {},
  sort: {
    value: "name",
    ascending: true,
  },
};

const processDeleteHashtag = (state, action) => {
  const newSelectedHashtags = state.selectedHashtags;
  if (action.name in newSelectedHashtags) {
    delete newSelectedHashtags[action.name];
  }
  return {
    ...state,
    message: {
      ...state.message,
      add: `Deleted hashtag: ${action.name}`,
    },
    selectedHashtags: newSelectedHashtags,
  };
};

const processAddHashtag = (state, action) => {
  return {
    ...state,
    message: {
      ...state.message,
      add: `Added new hashtag: ${action.name} to category ${action.category}`,
    },
  };
};

const processAddCategory = (state, action) => {
  return {
    ...state,
    message: {
      ...state.message,
      categories: `Added new category: ${action.category}`,
    },
  };
};

const processDeleteCategory = (state, action) => {
  return {
    ...state,
    message: {
      ...state.message,
      categories: `Deleted category: ${action.category}`,
    },
  };
};

const processDeleteCategoryAndHashtags = (state, action) => {
  return {
    ...state,
    message: {
      ...state.message,
      categories: `Deleted category and associated hashtags for: ${action.category}`,
    },
  };
};

const processSortHashtags = (state, action) => {
  return {
    ...state,
    sort: {
      value: action.property,
      ascending: action.ascending,
    },
  };
};

const processSelectHashtag = (state, action) => {
  const newSelectedHashtags = state.selectedHashtags;
  if (action.name in newSelectedHashtags) {
    delete newSelectedHashtags[action.name];
  } else {
    newSelectedHashtags[action.name] = action.name;
  }
  return {
    ...state,
    selectedHashtags: newSelectedHashtags,
  };
};

const processDeselectAllHashtags = (state, action) => {
  return {
    ...state,
    selectedHashtags: {},
  };
};

const processUpdateAddMessage = (state, action) => {
  return {
    ...state,
    message: {
      ...state.message,
      add: action.message,
    },
  };
};

const hashtagReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_HASHTAG":
      return processAddHashtag(state, action);
    case "ADD_HASHTAG_ERROR":
      console.log("Add hashtag error", action.err);
      return state;
    case "ADD_CATEGORY":
      return processAddCategory(state, action);
    case "ADD_CATEGORY_ERROR":
      console.log("Add category error", action.err);
      return state;
    case "UPDATE_CATEGORY":
      return state;
    case "UPDATE_CATEGORY_ERROR":
      console.log("Update category error", action.err);
      return state;
    case "DELETE_CATEGORY_SUCCESS":
      return processDeleteCategory(state, action);
    case "DELETE_CATEGORY_ERROR":
      console.log("Delete category error", action.err);
      return state;
    case "DELETE_CATEGORY_AND_HASHTAGS_SUCCESS":
      return processDeleteCategoryAndHashtags(state, action);
    case "DELETE_CATEGORY_AND_HASHTAGS_ERROR":
      console.log("Delete category and hashtags error", action.err);
      return state;
    case "SORT_HASHTAGS":
      return processSortHashtags(state, action);
    case "SELECT_HASHTAG":
      return processSelectHashtag(state, action);
    case "DESELECT_ALL_HASHTAGS":
      return processDeselectAllHashtags(state, action);
    case "DELETE_HASHTAG":
      return processDeleteHashtag(state, action);
    case "DELETE_HASHTAG_ERROR":
      console.log("Delete hashtag error", action.err);
      return state;
    case "UPDATE_ADD_MESSAGE":
      return processUpdateAddMessage(state, action);
    default:
      return state;
  }
};

export default hashtagReducer;
