import React, { useState, useEffect } from "react";
import Hashtags from "../hashtag/Hashtags";
import Sidebar from "../layout/sidebar/Sidebar";
import SelectControls from "../layout/SelectControls";
import OrganizeControls from "../layout/OrganizeControls";
import SidebarDrawer from "../layout/sidebar/SidebarDrawer";
import { connect } from "react-redux";
import {
  deleteHashtag,
  selectHashtag,
  sortHashtags,
  deselectAllHashtags,
} from "../../store/actions/hashtagActions";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import styled from "@emotion/styled";
import { Redirect } from "react-router-dom";
import { signOutUser } from "../../store/actions/authActions";
import Helmet from "react-helmet";
import { Feedback } from "../layout/FeedbackModal";

const DashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: 68px 0 1fr;
  grid-template-rows: 1fr;

  .sidebarDrawer {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(-1000%, 0);
    z-index: 1;
    width: 256px;
    box-shadow: 0px 0px 5px 0px #0000007a;

    .toggle {
      transistion: 0.3s all ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &.expanded {
    grid-template-columns: 68px 256px 1fr;

    .sidebarDrawer {
      transform: translate(0, 0);
    }
  }

  .feedback-button {
    background-color: var(--highlight-contrast);
    cursor: pointer;
    z-index: 3;
    transition: 0.3s all ease;
    position: fixed;
    bottom: -4px;
    left: 20px;
    color: #fff;
    font-size: 20px;
    border: 2px solid var(--bg-dark);

    &:hover {
      filter: brightness(1.1);
    }
  }

  @media screen and (max-width: 600px) {
    display: block;

    .sidebarDrawer {
      display: none;

      .toggle {
        display: none;
      }
    }

    &.expanded {
      .sidebarDrawer {
        display: block;
        width: 100%;
      }
    }
  }
`;

const Main = styled.div`
  padding: 1rem 1rem 4rem 1rem;
`;

const Dashboard = ({
  auth,
  hashtags,
  categories,
  selectedHashtags,
  deleteHashtag,
  selectHashtag,
  deselectAllHashtags,
  signOut,
  message,
}) => {
  const [generatedSet, setGeneratedSet] = useState(Array);
  const [sortNameAscending, setSortNameAscending] = useState(true);
  const [sortDateAscending, setSortDateAscending] = useState(true);
  const [sortCategoryAscending, setSortCategoryAscending] = useState(true);
  const [expandSidebar, setExpandSidebar] = useState(true);
  const [showSection, setShowSection] = useState("add");
  const [sortedHashtags, setSortedHashtags] = useState([]);
  const [feedbackModalVisibility, setFeedbackModalVisibility] = useState(false);

  useEffect(() => {
    const compareByName = (a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      let comparison = 0;
      if (aName > bName) {
        comparison = 1;
      } else if (aName < bName) {
        comparison = -1;
      }
      return comparison;
    };
    if (hashtags) {
      setSortedHashtags([...hashtags].sort(compareByName));
    }
  }, [hashtags]);

  const shuffleHashtags = (hashtags) => {
    for (let i = hashtags.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [hashtags[i], hashtags[j]] = [hashtags[j], hashtags[i]];
    }
    return hashtags;
  };

  const generateSet = (numberOfHashtags, selectedCategories) => {
    let setSize =
      numberOfHashtags > hashtags.length ? hashtags.length : numberOfHashtags;

    /* All hashtags that haven't been favorited */
    let notFavoriteHashtags = [...hashtags].filter(
      (hashtag) => !hashtag.favorite
    );

    /* If we're filtering by category, apply the filter */
    let notFavoriteHashtagsOfCategory = selectedCategories
      ? [...notFavoriteHashtags].filter((hashtag) =>
          selectedCategories.includes(hashtag.category)
        )
      : notFavoriteHashtags;

    /* Shuffled not favorite hashtags */
    let shuffledHashtags = shuffleHashtags(notFavoriteHashtagsOfCategory).map(
      (hashtag) => hashtag.name
    );

    /* All favorite hashtags */
    let favoriteHashtags = [...hashtags]
      .filter((hashtag) => hashtag.favorite)
      .map((hashtag) => hashtag.name);

    /* Generated set = shuffled(shuffled hashtags of desired size + shuffled favorites) */
    let randomHashtags = shuffleHashtags([
      ...shuffledHashtags.slice(0, setSize),
      ...favoriteHashtags,
    ]);

    setGeneratedSet(randomHashtags.join(" "));
  };

  const toggleSidebar = (section = null) => {
    if (section === showSection) {
      setExpandSidebar(!expandSidebar);
      setShowSection(section);
    } else {
      setExpandSidebar(true);
      setShowSection(section);
    }
  };

  const closeSidebar = () => {
    setExpandSidebar(false);
  };

  const updateSortBy = (property) => {
    if (property === "name") {
      setSortNameAscending(!sortNameAscending);
      setSortedHashtags([...hashtags].sort(compareByName));
    } else if (property === "dateAdded") {
      setSortDateAscending(!sortDateAscending);
      setSortedHashtags([...hashtags].sort(compareByDateAdded));
    } else if (property === "category") {
      setSortCategoryAscending(!sortCategoryAscending);
      setSortedHashtags([...hashtags].sort(compareByCategory));
    }
  };

  const compareByName = (a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    let comparison = 0;
    let factor = sortNameAscending ? 1 : -1;
    if (aName < bName) {
      comparison = 1 * factor;
    } else if (aName > bName) {
      comparison = -1 * factor;
    }
    return comparison;
  };

  const compareByDateAdded = (a, b) => {
    const aDate = a.dateAdded;
    const bDate = b.dateAdded;
    let comparison = 0;
    let factor = sortDateAscending ? 1 : -1;
    if (aDate > bDate) {
      comparison = 1 * factor;
    } else if (aDate < bDate) {
      comparison = -1 * factor;
    }
    return comparison;
  };

  const compareByCategory = (a, b) => {
    const aCategory = a.category.toLowerCase();
    const bCategory = b.category.toLowerCase();
    let comparison = 0;
    let factor = sortCategoryAscending ? 1 : -1;
    if (aCategory < bCategory) {
      comparison = 1 * factor;
    } else if (aCategory > bCategory) {
      comparison = -1 * factor;
    }
    return comparison;
  };

  const handleFeedbackClick = () => {
    setFeedbackModalVisibility(!feedbackModalVisibility);
  };

  if (!auth.uid) return <Redirect to="/sign-in" />;

  return (
    <DashboardWrapper className={expandSidebar ? "expanded" : null}>
      <Helmet>
        <title>Hashzag - Dashboard</title>
      </Helmet>
      <Sidebar signOut={signOut} toggleSidebar={toggleSidebar} />
      <SidebarDrawer
        message={message}
        categories={categories}
        hashtags={sortedHashtags}
        showSection={showSection}
        generateSet={generateSet}
        generatedSet={generatedSet}
        setGeneratedSet={setGeneratedSet}
        closeSidebar={closeSidebar}
      />
      <Main>
        <Feedback
          setShow={setFeedbackModalVisibility}
          show={feedbackModalVisibility}
          message="Thanks for trying Hashzag. I would love to hear from you."
        />
        <OrganizeControls sortBy={updateSortBy} hashtags={sortedHashtags} />
        <Hashtags
          deleteHashtag={deleteHashtag}
          selectHashtag={selectHashtag}
          selectedHashtags={selectedHashtags}
          hashtags={sortedHashtags}
        />
        <SelectControls
          hashtags={hashtags}
          selectedHashtags={selectedHashtags}
          deselectAllHashtags={deselectAllHashtags}
        />
        <button onClick={handleFeedbackClick} className="feedback-button">
          Feedback?
        </button>
      </Main>
    </DashboardWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectHashtag: (id, name) => dispatch(selectHashtag(id, name)),
    deleteHashtag: (id, name) => dispatch(deleteHashtag(id, name)),
    sortHashtags: (property, ascending) => {
      dispatch(sortHashtags(property, ascending));
    },
    deselectAllHashtags: () => dispatch(deselectAllHashtags()),
    signOut: () => dispatch(signOutUser()),
  };
};

const mapStateToProps = (state) => {
  return {
    ...state,
    hashtags:
      state.firestore.ordered.users &&
      state.firestore.ordered.users[0].hashtags,
    categories:
      state.firestore.ordered.users &&
      state.firestore.ordered.users[0].categories,
    message: state.hashtags.message,
    selectedHashtags: state.hashtags.selectedHashtags,
    auth: state.firebaseAuth.auth,
    sort: state.hashtags.sort,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [{ collection: "users", doc: props.auth.uid }];
  })
)(Dashboard);
