import React from "react";
import styled from "@emotion/styled";
import Name from "./Name";
import Category from "./Category";
import Date from "./Date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faHeart } from "@fortawesome/free-solid-svg-icons";
// import Moment from "react-moment";

const HashtagItem = styled.div`
  position: relative;
  padding: 10px 12px;
  margin: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: var(--bg);
  color: #eee;
  border: 1px solid transparent;
  box-shadow: 0px 0px 8px 3px #111429c2;
  transition: 0.3s all ease;

  &:hover {
    filter: brightness(1.3);
  }

  &.selected {
    background: var(--highlight);
    color: var(--text-dark);

    .category {
      color: var(--text-main);
      background: var(--bg-subtle);
    }

    .date {
      color: var(--text-dark);
    }
  }

  .name {
    font-weight: 900;
    font-size: 20px;
  }

  .category {
    background: var(--text-subtle);
    font-size: 11px;
    color: var(--text-dark);
    padding: 2px 4px;
    border-radius: 2px;
  }

  .date {
    margin: 0;
    font-weight: 700;
    font-size: 10px;
    color: var(--text-subtle);
  }

  .icon {
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s all ease;
    padding: 4px;
    display: flex;
    position: absolute;
  }

  .close-icon {
    background: #080715;
    top: -9px;
    left: -7px;
    width: 20px;
    height: 20px;

    &:hover {
      color: #bad0de;
    }
  }

  .heart-icon {
    color: #e409be;
    top: 0;
    right: 0;
    height: 16px;
    width: 16px;
  }
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Hashtags = ({
  hashtags,
  deleteHashtag,
  selectHashtag,
  selectedHashtags,
}) => {
  const hashtagList =
    hashtags &&
    hashtags.map((hashtag) => {
      return (
        <HashtagItem
          key={hashtag.name}
          className={hashtag.name in selectedHashtags ? "selected" : ""}
          onClick={() => {
            selectHashtag(hashtag.name);
          }}
        >
          <FontAwesomeIcon
            className="icon close-icon"
            color="#67679d"
            icon={faTimes}
            onClick={() => {
              deleteHashtag(hashtag.name);
            }}
          />
          <div>
            <Name name={hashtag.name} />
            <Category
              category={hashtag.category ? hashtag.category : "Uncategorized"}
            />
            <Date
              date_added={hashtag.dateAdded.toDate().toISOString().slice(0, 10)}
            />
            {hashtag.favorite ? (
              <FontAwesomeIcon
                className="icon heart-icon"
                color="#67679d"
                icon={faHeart}
              />
            ) : null}
          </div>
        </HashtagItem>
      );
    });

  return <HashtagWrapper>{hashtagList}</HashtagWrapper>;
};

export default Hashtags;
