import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOutUser } from "../../store/actions/authActions";

const SignOut = ({ signOut }) => {
    const history = useHistory();
    signOut();
    history.push("/");
    return null;
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOutUser()),
  };
};

export default connect(null, mapDispatchToProps)(SignOut);