import React, { useState, useRef, useEffect } from "react";
import Dropdown from "./Dropdown";

const ButtonWithDropdown = ({ triggerAction, buttonText, data, icon }) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(buttonText);
  const drop = useRef(null);
  function handleClick(e) {
    if (!e.target.closest(`.${drop.current.className}`) && open) {
      setOpen(false);
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
  return (
    <div className="dropdown-wrapper" ref={drop}>
      <div
        className={`dropdown-button ${open ? "open" : ""}`}
        open={open ? "open" : ""}
        onClick={() => setOpen((open) => !open)}
      >
        {icon} {selectedValue}
      </div>
      {open && (
        <Dropdown
          setSelectedValue={setSelectedValue}
          data={data}
          setOpen={setOpen}
          triggerAction={triggerAction}
        />
      )}
    </div>
  );
};

export default ButtonWithDropdown;
