import React, { useState } from "react";
import styled from "@emotion/styled";
import { Feedback } from "../layout/FeedbackModal";

const FooterWrapper = styled.div`
  .feedback-button {
    background-color: var(--highlight-contrast);
    cursor: pointer;
    z-index: 3;
    transition: 0.3s all ease;
    position: fixed;
    bottom: -4px;
    left: 20px;
    color: #fff;
    font-size: 20px;
    border: 2px solid var(--bg-dark);

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export default function Footer() {
  const [feedbackModalVisibility, setFeedbackModalVisibility] = useState(false);

  const handleFeedbackClick = () => {
    setFeedbackModalVisibility(!feedbackModalVisibility);
  };

  return (
    <FooterWrapper>
      <Feedback
        setShow={setFeedbackModalVisibility}
        show={feedbackModalVisibility}
        message="Thanks for checking out Hashzag. I would love to hear from you."
      />
      <button className="feedback-button" onClick={handleFeedbackClick}>
        Feedback?
      </button>
    </FooterWrapper>
  );
}
