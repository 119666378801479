import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const SidebarIconWrapper = styled.span`
  position: relative;
  margin: 1.5rem 0;

  &:hover {
    label {
      display: block;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      color: var(--highlight);
    }
  }

  label {
    display: none;
    position: absolute;
    z-index: 99;
    border-radius: 4px;
    left: 60px;
    top: -6px;

    span {
      background: var(--highlight);
      color: var(--text-dark);
      padding: 0.5rem 1rem;
      border-radius: 4px;
      white-space: nowrap;
      font-weight: 900;
      box-shadow: 0px 0px 1px 1px #00000054;
    }
  }

  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-gap: 20px;
    width: 100%;
    max-width: 100px;
    justify-content: center;
    align-items: center;
    margin: 0.25rem 0;

    label {
      position: unset;
      display: inline-block;

      span {
        background: var(--bg);
        color: var(--text-main);
        display: inline-block;
      }
    }

    .icon {
      position: relative;
      top: 2px;
    }
  }
`;

const SidebarIcon = ({ icon, label, onClick, cls }) => {
  return (
    <SidebarIconWrapper>
      {label ? (
        <label onClick={onClick}>
          <span>{label}</span>
        </label>
      ) : null}
      <FontAwesomeIcon
        className={`icon ${cls}`}
        color="#eee"
        icon={icon}
        onClick={onClick}
      />
    </SidebarIconWrapper>
  );
};

export default SidebarIcon;
