const initState = {
  signInError: null,
  signOutError: null,
  signUpError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGN_IN_SUCCESS":
      return {
        ...state,
        signInError: null,
      };
    case "SIGN_IN_ERROR":
      return {
        ...state,
        signInError: action.err.message,
      };
    case "SIGN_OUT_SUCCESS":
      return {
        ...state,
        signOutError: null,
      };
    case "SIGN_OUT_ERROR":
      return {
        ...state,
        signOutError: action.err.message,
      };
    case "SIGN_UP_SUCCESS":
      return {
        ...state,
        signUpError: null,
      };
    case "SIGN_UP_ERROR":
      return {
        ...state,
        signUpError: action.err.message,
      };
    default:
      return state;
  }
};

export default authReducer;
